export const impersonation: string | undefined = undefined
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const setImpersonation = (_newImpersonation: string | undefined) => {
  throw "impersonation disabled"
}

export const count: number | undefined = undefined

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const setCount = (_newCount: number) => {
  throw "count override disabled"
}

export const Config = {
  msalConfig: {
    auth: {
      clientId: "74bc6ae1-0c99-4378-9af9-32a5561e033f",
      authority:
        "https://login.microsoftonline.com/338159a0-dd8f-45af-9672-faaa54bc6b24",
      postLogoutRedirectUri: "https://feedbackhub.exxeta.info/#/"
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false
    }
  },
  apiURL: "https://6dufwxx5gk.execute-api.eu-central-1.amazonaws.com/prod/",
  msalScopes: ["api://682cb203-ff07-4237-8603-a83cdcb50a23/.default"],
  wfsId: "4bb77ddf-7657-40c0-998e-8ecc3136cdf1",
  postLoginRedirectUri: "https://feedbackhub.exxeta.info/",
  isDev: false
} as const
